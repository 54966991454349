import React from 'react';
import { useTheme } from 'styled-components';

import { $CircleProgressBar, $TextValue } from './CircleProgressBar.styles';

interface CircleProgressBarProps {
  size?: number;
  color?: string;
  value?: number;
  fontSize?: number;
  circleWidth?: number;
  withPercentage?: boolean;
}

const CircleProgressBar = ({
  size = 200, // betwween 50 and 250
  color,
  value = 0,
  fontSize = 52,
  withPercentage,
  circleWidth = 15,
}: CircleProgressBarProps) => {
  const theme = useTheme();
  const radius = size / 2 - 10;
  const circumference = 3.14 * radius * 2;
  const svgText = document.getElementById('circleProgressBarText');
  const textRect = svgText?.getBoundingClientRect();
  const textWidth = textRect?.width || 211;
  const textHeight = textRect?.height || 211;

  return (
    <$CircleProgressBar
      width={size}
      height={size}
      viewBox={`-${size * 0.125} -${size * 0.125} ${size * 1.25} ${
        size * 1.25
      }`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        r={radius}
        cx={size / 2}
        cy={size / 2}
        fill="transparent"
        stroke="#e0e0e0"
        strokeWidth={`${circleWidth}px`}
        strokeDasharray={circumference}
        strokeDashoffset="0"
      />
      <circle
        r={radius}
        cx={size / 2}
        cy={size / 2}
        stroke={color || theme.newUI.variationColors.dark1}
        strokeWidth={`${circleWidth}px`}
        strokeLinecap="round"
        strokeDashoffset={`${Math.round(
          circumference * ((100 - value) / 100),
        )}px`}
        fill="transparent"
        strokeDasharray={circumference}
      />
      {value !== undefined && (
        <$TextValue
          id="circleProgressBarText"
          x={`${Math.round(size / 2) - textWidth / 1.75}px`}
          y={`${Math.round(size / 2) + textHeight / 3.25}px`}
          fill={color || theme.newUI.variationColors.dark1}
          fontSize={`${fontSize}px`}
          fontWeight="bold"
          $size={size}
        >
          {`${value}${withPercentage ? '%' : ''}`}
        </$TextValue>
      )}
    </$CircleProgressBar>
  );
};

CircleProgressBar.displayName = 'CircleProgressBar';

export default CircleProgressBar;
