import { FEATURE_FLAGS, ROLES } from '@savgroup-front-common/constants';

import { useHasRole } from '../../../hooks/useHasRole';

import useIsFeatureEnabled from './useIsFeatureEnabled';

const useIsNewBoEnabled = (): boolean => {
  const isNewBoEnabled = useIsFeatureEnabled(FEATURE_FLAGS.BO_NEW_UI);
  const isDemoAdmin = useHasRole(ROLES.DEMO_ADMINISTRATOR);

  return isNewBoEnabled || isDemoAdmin;
};

export default useIsNewBoEnabled;
