import { APP_ENVS } from './envs';

export enum FEATURE_FLAGS_FOR_SELLER {
  BILLING = 'Billing',
  DOCUMENT_VISIBLE_ON_SELFCARE = 'DocumentSelfcare',
  SAFETY_RISK = 'SafetyRisk',
  PRODUCT_WITHOUT_CATALOG = 'ProductWithoutCatalog',
  HOLDER_VISIBILITY = 'HolderVisibility',
  QRCODE_UPLOAD = 'UploadByQrCode',
  MULTI_PRODUCT_CARRIER_LABELS = 'MultiProductCarrierLabels',
  COMMERCIAL_GESTURE_MANUAL_MODULE_INSTANCIATION = 'CommercialGestureManualModuleInstaciation',
  DISPLAY_REPAIRED_TAG = 'DisplayRepairedTag',
  OPTIONAL_EMAIL = 'OptionalEmail',
  DESACTIVATE_ACTOR_COVERAGE_RULE = 'DeactivateActorCoverageRule',
  QUALIREPAR = 'Qualirepar',
  QUOTE_INPUTATION = 'QuoteInputation',
  VIDEO_CALL = 'VideoCall',
  USER_ASSIGNMENT = 'UserAssignment',
  TAG_FILE_STATUS = 'TagFileStatus',
  DISPLAY_HOLDER_TYPE = 'DisplayHolderType',
  SEARCH_PRODUCT_SPAREPART = 'SearchProductSparePart',
  FILE_FILTER_PRESET = 'FileFilterPreset',
}

export enum FEATURE_FLAGS {
  BUSINESS_RULES = 'BUSINESS_RULES',
  PRICE_ORDER = 'PRICE_ORDER',
  AUTH_DUAL_LAYOUT = 'AUTH_DUAL_LAYOUT',
  CLIENT_ADDRESS_EDITION = 'CLIENT_ADDRESS_EDITION',
  REPORT_INTERVENTION = 'REPORT_INTERVENTION',
  REASON_FORCE_FILE_CLOSING = 'REASON_FORCE_FILE_CLOSING',
  SAFETY_RISK_STATUS = 'SAFETY_RISK_STATUS',
  DISPLAY_ACCOUNTING_TAB = 'DISPLAY_ACCOUNTING_TAB',
  DECLARE_BENEFICIARY = 'DECLARE_BENEFICIARY',
  BO_NEW_UI = 'BO_NEW_UI',
}

export enum FEATURE_FLAGS_DANGER_TYPE {
  SAFE = 'SAFE',
  DANGER = 'DANGER',
}

export enum FEATURE_FLAGS_APP_TYPE {
  CONTROL = 'CONTROL',
  AUTH = 'AUTH',
  SELFCARE = 'SELFCARE',
}

export interface FeatureFlagSummary {
  title?: string;
  description?: string;
  dangerType: FEATURE_FLAGS_DANGER_TYPE;
  app: FEATURE_FLAGS_APP_TYPE[];
  excludedEnv?: APP_ENVS[];
  forceActiveForThisTenantIds?: string[];
}

export const FEATURE_FAG_DESCRIPTIONS: Record<
  FEATURE_FLAGS,
  FeatureFlagSummary
> = {
  [FEATURE_FLAGS.AUTH_DUAL_LAYOUT]: {
    dangerType: FEATURE_FLAGS_DANGER_TYPE.SAFE,
    app: [FEATURE_FLAGS_APP_TYPE.AUTH],
    description: 'Display the new layout of authenticator',
  },
  [FEATURE_FLAGS.PRICE_ORDER]: {
    dangerType: FEATURE_FLAGS_DANGER_TYPE.DANGER,
    app: [FEATURE_FLAGS_APP_TYPE.AUTH],
    description: 'new login',
  },
  [FEATURE_FLAGS.BUSINESS_RULES]: {
    dangerType: FEATURE_FLAGS_DANGER_TYPE.SAFE,
    app: [FEATURE_FLAGS_APP_TYPE.CONTROL],
  },
  [FEATURE_FLAGS.CLIENT_ADDRESS_EDITION]: {
    description: 'Add button to edit address',
    dangerType: FEATURE_FLAGS_DANGER_TYPE.SAFE,
    app: [FEATURE_FLAGS_APP_TYPE.CONTROL],
    excludedEnv: [APP_ENVS.PROD],
  },
  [FEATURE_FLAGS.REPORT_INTERVENTION]: {
    description: 'Add New report intervention with Iris Code',
    dangerType: FEATURE_FLAGS_DANGER_TYPE.SAFE,
    app: [FEATURE_FLAGS_APP_TYPE.CONTROL],
  },
  [FEATURE_FLAGS.REASON_FORCE_FILE_CLOSING]: {
    description: 'Enhanced force close modal.',
    dangerType: FEATURE_FLAGS_DANGER_TYPE.SAFE,
    app: [FEATURE_FLAGS_APP_TYPE.CONTROL],
  },
  [FEATURE_FLAGS.SAFETY_RISK_STATUS]: {
    description: 'Add safety risk status checkbox',
    dangerType: FEATURE_FLAGS_DANGER_TYPE.SAFE,
    app: [FEATURE_FLAGS_APP_TYPE.CONTROL],
  },
  [FEATURE_FLAGS.DISPLAY_ACCOUNTING_TAB]: {
    dangerType: FEATURE_FLAGS_DANGER_TYPE.SAFE,
    app: [FEATURE_FLAGS_APP_TYPE.CONTROL],
    excludedEnv: [APP_ENVS.PROD],
  },
  [FEATURE_FLAGS.DECLARE_BENEFICIARY]: {
    description: 'Declare a new beneficiary',
    dangerType: FEATURE_FLAGS_DANGER_TYPE.SAFE,
    app: [FEATURE_FLAGS_APP_TYPE.CONTROL],
    excludedEnv: [APP_ENVS.PROD],
  },
  [FEATURE_FLAGS.BO_NEW_UI]: {
    description: 'BackOffice new UI',
    dangerType: FEATURE_FLAGS_DANGER_TYPE.SAFE,
    app: [FEATURE_FLAGS_APP_TYPE.CONTROL],
  },
};
